// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cAdE59gB2fAtRPTNL4rr{width:100%;height:100%;display:flex;justify-content:space-between;align-items:center}.Q5g62nnQiAkjss2bvjv_{border:unset;background-color:inherit;flex-grow:1;width:100px}.K3K9PQvh6pJwXhAYXmq4{background-color:#fff}.G9HbTQuNLy6q2zLRNUcJ{background-color:rgba(255,204,204,.8)}`, "",{"version":3,"sources":["webpack://./../../../dev/sharedUiComponents/dist/components/TextInputWithSubmit.modules.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,WAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CAGJ,sBACI,YAAA,CACA,wBAAA,CACA,WAAA,CACA,WAAA,CAGJ,sBACI,qBAAA,CAGJ,sBACI,qCAAA","sourcesContent":[".line {\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n\r\n.input {\r\n    border: unset;\r\n    background-color: inherit;\r\n    flex-grow: 1;\r\n    width: 100px;\r\n}\r\n\r\n.valid {\r\n    background-color: white;\r\n}\r\n\r\n.invalid {\r\n    background-color: rgba(255, 204, 204, 0.8);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"line": `cAdE59gB2fAtRPTNL4rr`,
	"input": `Q5g62nnQiAkjss2bvjv_`,
	"valid": `K3K9PQvh6pJwXhAYXmq4`,
	"invalid": `G9HbTQuNLy6q2zLRNUcJ`
};
export default ___CSS_LOADER_EXPORT___;
